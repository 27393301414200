import React, { useEffect } from "react";
import "./Blackbox.scss";
import { useHistory } from "react-router-dom";

export default ({ isOn, children }) => {
  const history = useHistory();

  const enableScrolling = () => document.body.classList.remove("locked");

  const disableScrolling = () => document.body.classList.add("locked");

  useEffect(() => {
    isOn ? disableScrolling() : enableScrolling();
  }, [isOn]);

  return (
    <div
      className={`helper-blackbox ${isOn ? "active" : ""}`}
      onClick={() => history.push("/")}
    >
      {children}
    </div>
  );
};
