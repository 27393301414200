import React from "react";
import "./ExitDummy.scss";
import { useHistory } from "react-router-dom";

export default () => {
  const history = useHistory();
  return (
    <div className="exit-dummy" onClick={() => history.push("/")}>
      <div className="exit-dummy-line" />
      <div className="exit-dummy-line opposite" />
    </div>
  );
};
