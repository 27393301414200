import React, { useState } from "react";
import Blackbox from "../Blackbox/Blackbox";
import ScrollHelper from "../ScrollHelper/ScrollHelper";
import "./Gallery.scss";
import ExitDummy from "../ExitDummy/ExitDummy";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Link, useLocation } from "react-router-dom";

export default function Gallery() {
  const [state, setState] = useState({
    currImg: 0,
    viewerIsOpen: false,
    isOpen: false,
    initialImageNumber: 8
  });

  const { pathname } = useLocation();
  const isOpen = pathname === "/galeria";

  const openViewer = (e, index) => {
    e.stopPropagation();
    setState({ ...state, currImg: index, viewerIsOpen: true });
  };

  const { currImg, viewerIsOpen } = state;

  const isMobile = window.innerWidth <= 768;
  const thumbnails = isOpen
    ? [...Array(41).keys()].map((image, index) => (
        <img
          key={index}
          src={
            isMobile
              ? require(`./Images/mob/${index + 1}.jpg`)
              : require(`./Images/${index + 1}.jpg`)
          }
          className="gallery-thumbnail"
          alt=""
          onClick={e => openViewer(e, index)}
        />
      ))
    : [];
  const imageSet = isOpen
    ? [...Array(41).keys()].map((image, index) => {
        return isMobile
          ? require(`./Images/mob/${index + 1}.jpg`)
          : require(`./Images/${index + 1}.jpg`);
      })
    : [];
  return (
    <section className="page-gallery">
      <Link to="/galeria">
        <div className="container">
          <button>
            <div className="gallery-button-darken">
              <div className="gallery-mock-button">
                <span>GALERIA</span>
              </div>
            </div>
          </button>
        </div>
      </Link>
      <Blackbox isOn={isOpen}>
        <ExitDummy />
        <div
          className="page-gallery-thumbnails"
          style={viewerIsOpen ? { overflow: "hidden" } : { overflow: "" }}
        >
          {thumbnails}
        </div>
      </Blackbox>
      <ScrollHelper className="page-footer-scroll" height="100px" />
      {viewerIsOpen && (
        <Lightbox
          mainSrc={imageSet[currImg]}
          nextSrc={imageSet[(currImg + 1) % imageSet.length]}
          prevSrc={imageSet[(currImg + imageSet.length - 1) % imageSet.length]}
          onCloseRequest={() => setState({ ...state, viewerIsOpen: false })}
          onMovePrevRequest={() =>
            setState({
              ...state,
              currImg: (currImg + imageSet.length - 1) % imageSet.length
            })
          }
          onMoveNextRequest={() =>
            setState({ ...state, currImg: (currImg + 1) % imageSet.length })
          }
          reactModalStyle={{
            overlay: {
              zIndex: "10000"
            }
          }}
          enableZoom={false}
        />
      )}
    </section>
  );
}
