import React from "react";
import "./FeelTheReel.scss";
import { useHistory } from "react-router";

export default () => {
  const history = useHistory();

  return (
    // this is because we can't have a tags inside a tags
    <div className="feel-the-reel" onClick={() => history.push("/show")}>
      <h2>Show taneczno-muzyczny</h2>
      <p>
        Naszym najnowszym projektem taneczno-muzycznym jest widowisko
        irlandzkie&nbsp;
        <a
          className="band-link"
          href="https://www.facebook.com/feelthereelshow/"
          target="_blank"
          rel="noopener noreferrer"
        >
          „Feel the Reel
        </a>
        " współtworzone razem z&nbsp;muzykami z&nbsp;zespołu&nbsp;
        <a
          className="band-link"
          href="http://www.taorluath.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Taorluath
        </a>
        . <span>CZYTAJ WIĘCEJ &gt;&gt;</span>
      </p>
    </div>
  );
};
