import React from "react";
import "./Offer.scss";

export default () => (
  <div className="offer">
    <h2>Oferta</h2>
    <p>
      Oferujemy pokazy taneczne do muzyki zarówno odtwarzanej jak i granej na
      żywo.&nbsp;&nbsp;
      <span>CZYTAJ WIĘCEJ &gt;&gt;</span>
    </p>
  </div>
);
