import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "../Components/Header/Header";
import Slider from "../Components/Slider/Slider";
import Gallery from "../Components/Gallery/Gallery";
import Videos from "../Components/Videos/Videos";
import Info from "../Components/Info/Info";
import Footer from "../Components/Footer/Footer";

import "./App.scss";

export default () => (
  <Router>
    <Header />
    <Slider />
    <Info />
    <Videos />
    <Gallery />
    <Footer />
  </Router>
);
