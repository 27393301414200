import React from "react";
import ScrollHelper from "../ScrollHelper/ScrollHelper";
import Blackbox from "../Blackbox/Blackbox";
import "./Info.scss";
import InfoCardShort from "./InfoCardShort/InfoCardShort";
import InfoCardLong from "./InfoCardLong/InfoCardLong";
import FeelTheReel from "./FeelTheReel/FeelTheReel";
import Offer from "./Offer/Offer";
import FeelTheReelLong from "./FeelTheReelLong/FeelTheReelLong";
import OfferLong from "./OfferLong/OfferLong";
import { Link, useLocation, Route } from "react-router-dom";
import Shows from "./Shows/Shows";

const Info = () => {
  const { pathname } = useLocation();

  return (
    <section className="page-info-card">
      <Blackbox isOn={pathname !== "/" && pathname !== "/galeria"}>
        <div className="container">
          <Route path="/info">
            <InfoCardLong isOn={pathname === "/info"} />
          </Route>
          <Route path="/show">
            <FeelTheReelLong />
          </Route>
          <Route path="/oferta">
            <OfferLong />
          </Route>
          <Route path="/koncerty">
            <Shows />
          </Route>
        </div>
      </Blackbox>

      <div className="container info-container">
        <Link to="/info">
          <InfoCardShort />
        </Link>
        {/* This one has to be toggled manually from inside */}
        <FeelTheReel />
        <Link to="/oferta">
          <Offer />
        </Link>
      </div>
      <ScrollHelper className="page-videos-scroll" height="100px" />
    </section>
  );
};

export default Info;
